import banners from './new-carriers-img.jpeg'; // Adjust the path based on your directory structure
import truck from './mountain2.bg.jpg'; // Adjust the path based on your directory structure
import about from './about.webp'; // Adjust the path based on your directory structure
import service1 from './transportation.jpg'; // Adjust the path based on your directory structure
import service2 from './support.jpg'; // Adjust the path based on your directory structure
import service3 from './specialized.jpg'; // Adjust the path based on your directory structure
import './App.css';
import Header from "./header";
import {useEffect} from "react";

function App() {
    useEffect(() => {
        const handleScroll = (e) => {
            const header = document.getElementById("header")
            if (window.scrollY > 110) {
                header.classList.add('scrolled');
            } else {
                header.classList.remove('scrolled');
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, []);
    const handleFormSubmit = (e) => {
        alert('Message sent successfully!');
    }
  return (
      <div className="App" id="home">
          <Header/>
          <div className="banner">
              <img src={truck} alt="banner"/>
              <div className="info">
                  <h1>Efficient & Reliable Logistics</h1>
                  <div className="line-horizontal"></div>
                  <span>Moving Your Goods, Moving Your Business</span>
              </div>
          </div>
          <div className="services" id="services">
              <h1>Services</h1>
              <div className="line-horizontal"></div>
              <div className="row">
                  <div className="column">
                      <div className="service-img-container">
                          <img src={service1} alt="service" width="360px" height="240px" style={{marginLeft: '160px', marginBottom: '35px'}}/>
                      </div>
                      <span className="service-name">Transportation Services</span>
                      <ul>
                          <li>Full Truck Load (FTL)</li>
                          <li>Less Than Truck Load (LTL)</li>
                          <li>USA & CANADA Transportations</li>
                      </ul>
                  </div>
                  <div className="column">
                      <div className="service-img-container">
                          <img src={service3} alt="service" width="450px" height="300px" style={{marginLeft: '10px', marginBottom: '-15px'}}/>
                      </div>
                      <span className="service-name">Specialized Services</span>
                      <ul>
                          <li>Project Cargo</li>
                          <li>Reverse Logistics</li>
                          <li>Hazardous Materials Handling</li>
                      </ul>
                  </div>
                  <div className="column">
                      <div className="service-img-container">
                          <img src={service2} alt="service" width="350px" height="226px" style={{marginLeft: '-130px'}}/>
                      </div>
                      <span className="service-name">Customer Support</span>
                      <ul>
                          <li>24/7 Customer Service</li>
                          <li>Claims Management</li>
                          <li>Vehicle Maintenance</li>
                      </ul>
                  </div>
              </div>
          </div>
          <div className="banner">
              <img className="banners" src={banners} alt="banner"/>
              <div className="info left">
              <h1>Secure & Timely Deliveries</h1>
                  <div className="line-horizontal"></div>
                  <span>Your Cargo, Our Priority</span>
              </div>
          </div>
          <div className="about" id="about">
              <div className="about-img-cont">
                  <img className="about-img" src={about} alt="about"/>
              </div>
              <div className="about-info">
                  <h1>About Us</h1>
                  <div className="line-horizontal"></div>
                  <span>With over a decade of experience in the logistics industry, we provide comprehensive solutions to streamline your supply chain. Our dedicated team ensures timely and secure transportation of your goods, enhancing your business operations. Experience unparalleled efficiency and reliability with our expert services.</span>
              </div>
          </div>

          <div className="contact" id="contact">
              <h1>Contact</h1>
              <div className="line-horizontal"></div>
              <div className="form-container">
                  <div className="contact-row">
                      <div className="contact-col">
                          <span>Email</span>
                          <div className="icon-text">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="21.2" height="16.5"
                                   viewBox="0 0 512 512" fill="#b12b24"><title>envelope-o</title>
                                  <path
                                      d="M475.428 429.714v-219.429c-6 6.857-12.572 13.143-19.714 18.857-40.857 31.428-82 63.428-121.714 96.572-21.428 18-48 40-77.714 40h-0.571c-29.715 0-56.285-22-77.715-40-39.714-33.143-80.857-65.143-121.715-96.572-7.143-5.714-13.715-12-19.715-18.857v219.429c0 4.857 4.285 9.143 9.143 9.143h420.572c4.857 0 9.143-4.286 9.143-9.143zM475.428 129.429c0-7.143 1.714-19.715-9.143-19.715h-420.572c-4.857 0-9.143 4.285-9.143 9.143 0 32.572 16.285 60.857 42 81.143 38.285 30 76.571 60.286 114.571 90.572 15.143 12.286 42.572 38.572 62.572 38.572h0.572c20 0 47.428-26.286 62.572-38.572 38-30.286 76.286-60.572 114.572-90.572 18.572-14.572 42-46.286 42-70.572zM512 118.857v310.857c0 25.143-20.572 45.714-45.714 45.714h-420.572c-25.143 0-45.715-20.572-45.715-45.714v-310.857c0-25.143 20.572-45.715 45.715-45.715h420.572c25.143 0 45.714 20.572 45.714 45.715z"/>
                              </svg>
                              <a href="mailto:cargograminc@gmail.com">cargograminc@gmail.com</a>
                          </div>
                          <span>Contact Info</span>
                          <div className="icon-text">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="21.2" height="16.5"
                                   fill="#b12b24"
                                   viewBox="0 0 402 512"><title>phone</title>
                                  <path
                                      d="M402.286 354.286c0 10.286-4.572 30.286-8.857 39.714-6 14-22 23.143-34.857 30.286-16.857 9.143-34 14.572-53.143 14.572-26.572 0-50.572-10.857-74.857-19.714-17.429-6.286-34.285-14-50-23.714-48.571-30-107.143-88.572-137.143-137.143-9.715-15.714-17.428-32.572-23.715-50-8.857-24.286-19.715-48.286-19.715-74.857 0-19.143 5.428-36.285 14.572-53.143 7.143-12.857 16.285-28.857 30.285-34.857 9.428-4.285 29.428-8.857 39.715-8.857 2 0 4 0 6 0.857 6 2 12.285 16 15.143 21.715 9.143 16.285 18 32.857 27.428 48.857 4.572 7.428 13.143 16.572 13.143 25.428 0 17.429-51.715 42.857-51.715 58.285 0 7.714 7.143 17.714 11.143 24.572 28.857 52 64.857 88 116.857 116.857 6.857 4 16.857 11.143 24.572 11.143 15.428 0 40.857-51.714 58.286-51.714 8.857 0 18 8.572 25.428 13.143 16 9.428 32.572 18.286 48.857 27.428 5.714 2.857 19.714 9.143 21.714 15.143 0.857 2 0.857 4 0.857 6z"/>
                              </svg>
                              <a href="tel:+13198000881">1 (319) 800-0881</a>
                          </div>
                      </div>
                      <div className="contact-col">
                          <span>Address</span>
                          <span className="text-black">1015 South Grand Avenue, Ames, IA 50010-6052 USA</span>
                          <div className="icon-text">
                              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="21.2" height="16.5"
                                   fill="#b12b24"
                                   viewBox="0 0 439 512"><title>clock-o</title>
                                  <path
                                      d="M256 155.429v128c0 5.143-4 9.143-9.143 9.143h-91.429c-5.143 0-9.143-4-9.143-9.143v-18.286c0-5.143 4-9.143 9.143-9.143h64v-100.571c0-5.143 4-9.143 9.143-9.143h18.285c5.143 0 9.143 4 9.143 9.143zM374.857 256c0-85.714-69.714-155.429-155.429-155.429s-155.429 69.715-155.429 155.429 69.714 155.428 155.429 155.428 155.429-69.714 155.429-155.429zM438.857 256c0 121.143-98.286 219.428-219.429 219.428s-219.429-98.286-219.429-219.429 98.285-219.429 219.429-219.429 219.429 98.286 219.429 219.429z"/>
                              </svg>
                              <span>Mon-Fri - 09:00-17:00</span>
                          </div>
                      </div>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                      <div>
                          <input type="text" name="name" id="name" placeholder="Name" required/>
                          <input type="text" name="phone" id="phone" placeholder="Phone" required/>
                      </div>
                      <input type="text" name="email" id="email" placeholder="Email" required/>
                      <input type="text" name="message" id="message" placeholder="Message" required/>
                      <button type="submit" >CONTACT US</button>
                  </form>
              </div>
          </div>
          <div className="footer">
              <div className="footer-copyright">
                  <span className="company">Cargogram INC</span>
                  <span className="copy">Copyright © 2024 All rights reserved</span>
              </div>
              <div className="footer-nav">
                  <a href="#home">Home</a>
                  <a href="#services">Services</a>
                  <a href="#about">About</a>
                  <a href="#contact">Contact</a>
              </div>
          </div>
      </div>
  );
}

export default App;
